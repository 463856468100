.no-thanks-dialog-waiting {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 24px;
	box-sizing: border-box;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}

.no-thanks-dialog-waiting__inner {
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 10px;
	padding: 60px 24px;
	width: 600px;
	margin: 0 auto;
	text-align: center;
	position: relative;
}

.no-thanks-dialog-waiting__buttons {
	margin-top: 40px;
	display: inline-flex;
	gap: 12px
}

.no-thanks-dialog-waiting__close {
	position: absolute;
	left: 20px;
	top: 20px;
	cursor: pointer;
}