.no-thanks-home-page {
	display: flex;
	min-height: 100%;
}

.no-thanks-home-page__content {
	flex: 1;
	padding: 30px;
}

.no-thanks-home-page__right-sidebar {
	flex: 0 0 250px;
	background: #fff;
	box-shadow: -8px 10px 15px 0 rgba(0, 0, 0, 0.25);
	padding: 24px;
}

.no-thanks-home-page__right-sidebar .no-thanks-home-page__right-sidebar__title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
}

.no-thanks-home-page__right-sidebar .no-thanks-home-page__right-sidebar-list-item {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid #f0f0f0;
}

.no-thanks-home-page__right-sidebar .no-thanks-home-page__right-sidebar-list-item-nickname {
	font-weight: bold;
	word-break: break-word;
}

.no-thanks-home-page__right-sidebar .no-thanks-home-page__right-sidebar-list-item-score {
	flex: 0 0 auto;
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	color: #000;
	line-height: 1;
	min-height: 24px;
}

.no-thanks-home-page__right-sidebar .no-thanks-home-page__right-sidebar-list-item-score-img {
	flex: 0 0 auto;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: url('../img/total_score.jpg') no-repeat center center;
	background-size: cover;
	margin-right: 4px;
}

.message-no-rooms {
	color: #666;
	text-align: center;
	padding-top: 10px;
}