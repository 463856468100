.card-empty {
	background: rgba(255, 255, 255, 0.8);
	width: 150px;
	height: 200px;
	border-radius: 10px;
	border: 1px solid #ccd;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 40px;
}

.card-empty__area {
	position: relative;
	flex: 1;
	width: 100%;
}

.card-empty .no-thanks__coin {
	flex: none;
}