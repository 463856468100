.copyright {
	padding: 24px;
	font-size: 15px;
	color: #fff;
	text-align: center;
}

.copyright a {
	color: #99ff00;
	font-weight: bold;
}