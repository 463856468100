.card-front {
	background: url('./cardFront.png') no-repeat left -1px top -1px;
	background-size: 101.5% 101.5%;
	border-radius: 10px;
	border: 1px solid #ccd;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	width: var(--card-width);
	height: var(--card-height);
}

.card-front__group {
	position: relative;
	height: 50%;
}

.card-front__group.flipped {
	transform: scaleY(-1) scaleX(-1);
}

.card-front__value {
	position: absolute;
	left: 50%;
	bottom: 6px;
	font-size: calc(var(--card-width) / 3);
	transform: translateX(-50%);
	line-height: 1;
	font-weight: bold;
}

.card-front__value--tl {
	font-size: calc(var(--card-width) / 10);
	position: absolute;
	left: 15%;
	top: 19%;
}

.card-front__value--tr {
	font-size: calc(var(--card-width) / 10);
	position: absolute;
	right: 15%;
	top: 19%;
}