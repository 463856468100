.home-logo {
	width: 150px;
	height: 150px;
	margin: 40px auto 0 auto;
	background: url('../../img/logo.png') no-repeat center center;
	background-size: contain;
}

.home-buttons {
	margin-top: 20px;
	display: flex;
	gap: 20px;
}