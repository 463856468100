.no-thanks-battle-page {
	min-height: calc(100% - 96px);
	background: #fbf5db url('../img/main_bg.jpeg') repeat left top;
	display: flex;
}

.no-thanks-battle-page__body {
	overflow: hidden;
	flex: 1;
	padding-bottom: 24px;
	backdrop-filter: blur(3px);
}

.no-thanks__info_panel {
	background: #fff;
	text-align: center;
	padding: 15px;
	margin-top: 15px
}

.no-thanks__info_panel__button {
	padding: 6px 10px;
	margin: -6px 0;
}

.no-thanks__cards_panel {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 15px
}

.no-thanks__autoplay_panel {
	background: #fff;
	text-align: center;
	padding: 15px;
	margin-top: 15px
}

.no-thanks__autoplay_panel-option {
	margin-left: 5px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 30px;
	display: inline-block;
	cursor: pointer;
	background: #695d91;
	color: #fff;
}

.no-thanks__autoplay_panel-option--selected {
	background: #f8eab1;
	color: #000;
}

.no-thanks__picked-cards {
	margin-top: 15px;
}

.no-thanks__picked-cards-line {
	background: rgba(255, 255, 255, 0.9);
	margin-top: 10px;

}
.no-thanks__picked-cards-nickname {
	padding-left: 13px;
	font-weight: bold;
}

.no-thanks__picked-cards-list {
	overflow-x: auto;
	overflow-y: hidden;
}

.no-thanks__picked-cards-list-inner {
	display: flex;
	padding-top: 5px;
}

.no-thanks__picked-cards-list-inner .card-front {
	margin-bottom: calc( var(--card-height) / -2 );
	box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
	margin-left: 5px;
	font-weight: bold;
	flex: none;
}

.no-thanks__picked-cards-list-inner .card-front.collapse-with-previous-card {
	margin-left: calc( var(--card-width) * -0.7 );
}

.no-thanks__popup-took-card {
	position: absolute;
	background: #fff;
	border-radius: 10px;
	font-size: 20px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 1;
	transition: opacity 0.5s;
}

.no-thanks__popup-took-card-nickname {
	font-weight: bold;
	word-break: break-word;
	padding: 0 15px;
}

.no-thanks__popup-took-card-connect {
	font-size: 15px;
}

.no-thanks__popup-took-card-number {
	font-size: 50px;
	font-weight: bold;
	line-height: 1;
}