.card-back {
	background: url('./cardBack.png') no-repeat left -1px center;
	background-size: contain;
	width: 150px;
	height: 200px;
	border-radius: 10px;
	border: 1px solid #ccd;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #999;
}

.card-back__body {
	background: #fff;
	border-radius: 10px;
}

.card-back__number {
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 65px;
	line-height: 1;
}

.card-back__text {
	border-radius: 3px;
	padding: 5px;
	line-height: 1;
	margin-top: -5px;
}