.no-thanks-dialog-finished {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 24px;
	box-sizing: border-box;
	overflow: auto;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}

.no-thanks-dialog-finished__inner {
	background: #fff;
	border-radius: 10px;
	padding: 40px 24px 60px;
	width: 600px;
	margin: 0 auto;
	text-align: center;
	position: relative;
}

.no-thanks-dialog-finished__close {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
}

.no-thanks-dialog-finished__header {
	font-size: 24px;
	font-weight: 800;
	text-align: center;
	margin-bottom: 20px;
	text-transform: uppercase;
	border-bottom: 1px solid #000;
	padding-bottom: 30px;
}

.no-thanks-dialog-finished__header-icon {
	position: relative;
	top: -2px;
	padding-right: 3px;
}
.no-thanks-dialog-finished__header-user {
	color: #00f;
}

.no-thanks-dialog-finished__member {
	display: flex;
	align-items: center;
	padding-bottom: 20px;
}

.no-thanks-dialog-finished__member-place {
	flex: 0 0 84px;
	font-size: 18px;
	font-weight: 800;
	text-align: center;
}

.no-thanks-dialog-finished__member-info {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 800;
	text-align: left;
	word-break: break-word;
	margin-right: 20px;
}

.no-thanks-dialog-finished__member-score {
	flex: 0 0 50px;
	display: flex;
	gap: 3px;
}

.no-thanks-dialog-finished__member-results {
	margin-left: auto;
	padding-right: 20px;
	display: flex;
	gap: 2px;
}

.no-thanks-dialog-finished__member-results svg {
	margin: 0 5px;
}

.no-thanks-dialog-finished__member-results-img {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: url('../../../img/total_score.jpg') no-repeat center center;
	background-size: cover;
	margin-right: 2px;
}

.no-thanks-dialog-finished__member-results-new {
	width: 26px;
}

.no-thanks-dialog-finished__member-info-avatar {
	flex: 0 0 auto;
	width: 50px;
	height: 50px;
	
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.no-thanks-dialog-finished__member-info-avatar-image {
	width: 30px;
	height: 33px;
	background-color: #fff;
	background: url('../../../img/user.jpg') no-repeat center center;
	background-size: cover;
	opacity: 0.5;
}

.no-thanks-dialog-finished__member-info-avatar-image--real {
	width: 100%;
	height: 100%;
	opacity: 1;
	border-radius: 50%;
}