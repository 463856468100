.logo {
	width: 60px;
	height: 60px;
	background: url('../../img/logo.png') no-repeat center center;
	background-size: contain;
}

.header {
	/* background: linear-gradient(-45deg, #fda085, #9b8333, #1657bf, #628c9f) center center;
	background-size: 400% 400%; */
	background: var(--bg);
	box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.5);
	position: relative;
	z-index: 10;
}

header.header--logged-out {
	display: flex;
	justify-content: center;
}