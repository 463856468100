.right-sidebar {
	width: 250px;
	flex: none;
	background: #fff;
	box-shadow: -8px 10px 15px 0 rgba(0, 0, 0, 0.25);
	position: relative;
	z-index: 2;
}

.right-sidebar__member {
	padding: 10px;
	border-bottom: 3px solid #f0f0f0;
	display: grid;
	grid-template:  "meta main total-score";
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto auto;
}

.right-sidebar__member-meta {
	grid-area: meta;
}

.right-sidebar__member-avatar {
	width: 50px;
	height: 50px;
	
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	background-color: #fff;
	margin: 0 10px 5px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.right-sidebar__member-avatar-image {
	width: 30px;
	height: 33px;
	background-color: #fff;
	background: url('../../../img/user.jpg') no-repeat center center;
	background-size: cover;
	opacity: 0.5;
}

.right-sidebar__member-avatar-image--real {
	width: 100%;
	height: 100%;
	opacity: 1;
	border-radius: 50%;
}

@keyframes rotateTimer {
	0%, 25% {
		transform: rotate(0deg);
	}
	50%, 100% {
		transform: rotate(180deg);
	}
}

.right-sidebar__member-avatar-turn-sand {
	width: 25px;
	height: 30px;
	background-color: #fff;
	background: url('../../../img/sand_timer.jpg') no-repeat center center;
	background-size: contain;
	opacity: 0.75;
	animation: rotateTimer 5s infinite linear;
}

.right-sidebar__member-main {
	grid-area: main;
}

.right-sidebar__member-nickname {
	font-size: 16px;
	font-weight: bold;
	
}

.right-sidebar__member-score {
	display: flex;
	align-items: center;
	line-height: 1;
	padding-bottom: 10px;
}

.right-sidebar__member-score-img {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: url('../../../img/star.jpg') no-repeat center center;
	background-size: cover;
	margin-right: 2px;
}

.right-sidebar__member-total-score {
	grid-area: total-score;
	display: flex;
	align-items: center;
	height: 30px;
	font-size: 14px;
	color: #000;
}

.right-sidebar__member-total-score-img {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: url('../../../img/total_score.jpg') no-repeat center center;
	background-size: cover;
	margin-right: 2px;
}

.right-sidebar__member-chips {
	display: flex;
	align-items: center;
	gap: 5px;
}