.home-page__mobile_placeholder {
	display: none;
}

@media (max-width: 700px) {
	.home-page__mobile_placeholder {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}