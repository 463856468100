@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loader {
	border-top-color: #3498db; /* Set the color of the spinner here */
	animation: spin 1s ease-in-out infinite;
}

:root {
	--bg: linear-gradient(to right, rgb(59, 130, 246), rgb(20, 184, 166));
}

html, body, #root {
	height: 100%;
}

.page-simple {
	background: var(--bg);
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

.page-simple__content {
	padding: 24px 24px 0;
	flex: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}